// Packages
import { useState, useEffect, useCallback, } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import debounce from 'lodash.debounce';

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useApi from "../../../../api";

// Controllers
import useControllers from "../../..";

const useNotificationView = () => {
  // State
  //const [listGroups, setListGroup] = useState([]);
  const [listNotifications, setListNotifications] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [countrySelectFilter, setCountrySelectFilter] = useState(null);
  const [modalDelete, setModalDelete] = useState({ show: false, name: "", id: 0 });
  const [paginationSettings, setPaginationSettings] = useState({ current: 1, totalPages: 1 });
  const [valuesFilter, setValuesFilter] = useState({});


  // Actions
  const { useActions } = useApi();
  const { useUsersActions, dispatch, useGeneralActions, useNotificationsActions } = useActions();
  /* const { actListUsers, actSelectUser } = useUsersActions(); */
  const { actListNotifications, actGetNotification, actCloneNotification
    , actEditNotification, actDeleteNotification } = useNotificationsActions();
  const { actListCountries, actExportExel } = useGeneralActions();

  // Helpersf
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useToast } = useQuickFunctions();
  const { filterNotificationValidationShema } = useValidators();
  const { success, error } = useToast();
  const navigation = useNavigate();

  const { useGeneralHooks } = useControllers();
  const { prepareDataFilterDownload, createDownloadExel } = useGeneralHooks();

  const { control, handleSubmit } = useForm({
    mode: "all",
    resolver: yupResolver(filterNotificationValidationShema),
  });
  
  const onSubmit = (data, e) => {
    e.preventDefault();
    const formData = {
      state: data.status,
      type: data.type,
    };

    setValuesFilter(formData)
    actListNotifications({page: 1, search: searchText, ...formData}, loadData);    
  };

  const loadData = (data) => {
    setListNotifications(data.data);
    setPaginationSettings({ current: data.meta.current_page, totalPages: data.meta.last_page });
  };

  const handleDeleteNotification = (id, name) => {
    setModalDelete({ show: true, name: name, id: id });
  };

  const handleConfirmDelete = () => {
    actDeleteNotification(modalDelete.id, onSubmitSuccess);
    setModalDelete({ show: false, name: "", id: 0 });
  };

  const onSubmitSuccess = () => {
    success("Notificación eliminada con éxito!");
    actListNotifications({page: 1}, loadData);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloneNotification = (id) => {
    actCloneNotification({id: id}, onSubmitSuccessClone);
  }

  const onSubmitSuccessClone = () => {
    success("Notificación duplicada con éxito!");
    actListNotifications({page: paginationSettings.current, search: searchText, ...valuesFilter}, loadData);
  };
  
  const handleCreateNewNotification = () => {
    navigation("/notificaciones/creacion");
  };

  const handleEditNotification = (group) => {
    navigation(`/notificaciones/editar/${group.id}`);
  };

  const prepareDataCountry = (data) => {
    setListCountries(
      data.map((v) => {
        return { label: v.name, value: v.id };
      })
    );
  };

  const handlePagination = (nro) => {    
    actListNotifications({page: nro.selected + 1, search: searchText}, loadData);
  };

  const onSubmitSearch = (e) => {    
    setSearchText(e) 
    actListNotifications({page: 1, search: e}, loadData);
  };

  const onChangeSearch = useCallback(
    debounce(onSubmitSearch, 750)
  , []);

  const handleDownloadExcel = () => {
    const filtersProcces = prepareDataFilterDownload(valuesFilter);
    actExportExel(`notifications`, filtersProcces, (result) => {
      createDownloadExel(result);
    });
  };

  useEffect(() => {    
    actListNotifications({page: 1}, loadData);
    actListCountries(prepareDataCountry);
  }, []);

  return {
    control,
    handleSubmit,
    onSubmit,
    listNotifications,
    handleCreateNewNotification,
    handleEditNotification,
    handleCloneNotification,
    handleDeleteNotification,
    handleConfirmDelete,
    handleCloseModal,
    modalDelete,
    listCountries,
    paginationSettings,
    handlePagination,
    onChangeSearch,
    handleDownloadExcel
  };
};

export default useNotificationView;
