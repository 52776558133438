import React from "react";
import { createRoot } from 'react-dom/client';

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Configuración de Raven (Sentry)
import Raven from "raven-js";
Raven.config("https://f08e2f100a1b6b015d6991945ce65205@sentry.serempre.dev/10").install();

// Cargar la API de Google Maps dinámicamente
const loadGoogleMaps = () => {
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_GOOGLE}&libraries=places`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
};

loadGoogleMaps(); // Llamamos la función para cargar el script


const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
