import { useNavigate } from "react-router-dom";
import useQuestionsMain from "./Main";
import useCreateQuestions from "./Create";
import useEditQuestions from "./Edit";

const useQuestions = () => {
  const navigation = useNavigate();
  const handleBack = () => {
    navigation(-1);
  };
  const header = {
    "/qualitychecks": ["Preguntas"],
    "/qualitychecks/editar": ["Preguntas", "Editar Pregunta"],
    "/qualitychecks/creacion": ["Preguntas", "Crear Pregunta"],
  };
  return { useQuestionsMain, useCreateQuestions, useEditQuestions, header, handleBack };
};

export default useQuestions;
