// Packages
import React from "react";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

// Models
import useModels from "../../../../../../models";

// Components
import useComponents from "../../../../../components";

// Controllers
import useControllers from "../../../../../../controllers";

// Assets
import useIcons from "../../../../../../assets/icons";

// Constants
import {
  AI_SECTION_TITLE,
  AI_PROMPT_ID,
  AI_PROMPT_MAX_LENGTH,
  AI_PROMPT_EXAMPLES,
} from "../../../../../../config/constants/toolsAI";

// Styles
import {
  StyledContainer,
  StyledWrapper,
  StyledCardContainerPrompt,
  StyledLogoContainer,
  StyledHeaderPrompt,
  StyledDescriptionPrompt,
  StyledSurveyBackground,
  StyledWrapperSurvey,
  StyledHeaderSurvey,
  StyledCardWrapperPrompt,
  StyledQuestionsHeader,
  StyledQuestionsHeaderItems,
  StyledQuestionsContent,
  StyledQuestionsList,
  StyledSubmitButtonContainer,
} from "./AISurvey.styles";

const AISurvey = () => {
  const toolStateAI = useSelector((state) => state.organizeToolsAI);
  const { useSelectors } = useModels();
  const { useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();
  const { loader } = useSelector(session);

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useToolsCreateAI } = useTools();
  const {
    control,
    handleCloseConfirmationModal,
    handleEditQuestion,
    handleEditOption,
    handleMakeQuestionEditable,
    handleChangeQuestionVisibility,
    handleRemoveQuestion,
    handleRemoveOption,
    handleSubmit,
    isQuestionEditable,
    isQuestionVisible,
    onSubmitPrompt,
    onSubmitSurvey,
    showConfirmationModal,
    watchAIPrompt,
    handleAddOption,
    getPromptByCategory,
    promptByCategoryControl,
    promptCategories,
    onSubmit,
    promptList,
    setPrompt,
    watchCategories,
  } = useToolsCreateAI();

  // Components
  const {
    CardWrapper,
    QuestionListAI,
    TextAreaFieldControlled,
    Typography,
    useButtons,
    useModals,
    SelectMultiFieldControlled,
    useSpinners,
    Badge
  } = useComponents();

  const { DefaultButton, FilterButton } = useButtons();
  const { DefaultModal } = useModals();
  const { SpinnerLoading } = useSpinners();

  // Assets
  const { IconLogo } = useIcons();

  return (
    <StyledContainer>
      <StyledWrapper
        gridColums={["1fr", "3fr"]}
        gap={{
          row: "0px",
          column: "2rem",
        }}
        as="section"
      >
        <CardWrapper padding="1rem">
          <StyledCardContainerPrompt>
            <div>
              <StyledLogoContainer>
                <IconLogo />
              </StyledLogoContainer>
              <StyledHeaderPrompt>Genera tu encuesta</StyledHeaderPrompt>
              <StyledDescriptionPrompt>
                Descríbele a la inteligencia artificial los detalles de la encuesta que deseas
                generar.
              </StyledDescriptionPrompt>
            </div>
            <form className="mt-6" onSubmit={handleSubmit(onSubmitPrompt)}>
              <TextAreaFieldControlled
                control={control}
                id={AI_PROMPT_ID}
                maxLength={AI_PROMPT_MAX_LENGTH}
                maxRows={10}
                minRows={10}
                name="ai_prompt"
                placeholder={
                  AI_PROMPT_EXAMPLES[Math.floor(Math.random() * AI_PROMPT_EXAMPLES.length)]
                }
                resize="none"
                fullHeight={true}
              />
              <div className="bg-gray-50 p-2 mb-2 rounded-md">
                <StyledDescriptionPrompt>
                  ¿Necesitas ayuda? Selecciona las categorías que se relacionen con la encuesta que
                  quieres generar
                </StyledDescriptionPrompt>
                <FilterButton  fullWidth handleSubmit={getPromptByCategory(onSubmit)}>
                  <SelectMultiFieldControlled
                    className={"w-full"}
                    label={"Categorias"}
                    id={"categories"}
                    name={"categories"}
                    options={promptCategories || []}
                    control={promptByCategoryControl}
                  />
                </FilterButton>
                <div className="p-2 mt-2 mb-2">
                  {
                    watchCategories?.map(category => 
                      (<Badge className={"bg-primary"} label={category.label}/>)
                    )
                  }
                </div>
                <StyledDescriptionPrompt>
                  Selecciona un prompt para editarlo o usarlo en una encuesta
                </StyledDescriptionPrompt>
                <div className="p-2 mt-2 mb-2 overflow-y-scroll h-48 max-h-48">
                  {promptList?.map((prompt, index) => (
                    <DefaultButton
                      key={index}
                      rounded
                      variant={"gray"}
                      onClick={() => setPrompt("ai_prompt", prompt.prompt)}
                    >
                      {prompt.name}
                    </DefaultButton>
                  ))}
                </div>
              </div>
              <div className="justify-center flex">
                <DefaultButton
                  color="primary"
                  type="submit"
                  rounded
                  fullWidth
                  disabled={!watchAIPrompt}
                >
                  Generar encuesta
                </DefaultButton>
              </div>
            </form>
          </StyledCardContainerPrompt>
        </CardWrapper>

        <div>
          <form onSubmit={onSubmitSurvey}>
            <StyledSurveyBackground>
              <StyledHeaderSurvey>Encuesta por inteligencia artificial</StyledHeaderSurvey>
              {toolStateAI?.questions?.length > 0 ? (
                <StyledWrapperSurvey>
                  <StyledCardWrapperPrompt padding="2rem">
                    <StyledQuestionsHeader gridColums={["330px", "395px", "102px"]}>
                      <StyledQuestionsHeaderItems>
                        <Typography variant="h5">ENCUESTA</Typography>
                        <Typography variant="caption">{toolStateAI.name}</Typography>
                      </StyledQuestionsHeaderItems>
                    </StyledQuestionsHeader>

                    <StyledQuestionsContent>
                      <Typography variant="h2">PREGUNTAS</Typography>

                      <Typography variant="h3" className="uppercase">
                        <span>SECCIÓN:</span> {AI_SECTION_TITLE}
                      </Typography>

                      <>
                        <Typography variant="h4" bulledTitle={true} bulledColor="#CECAC1">
                          PREGUNTAS GENERADAS
                        </Typography>
                        <DragDropContext>
                          <Droppable droppableId="dragDropAISurvey">
                            {(provided) => (
                              <StyledQuestionsList
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {toolStateAI.questions.map((question, index) => (
                                  <Draggable
                                    isDragDisabled={false}
                                    key={question.title}
                                    draggableId={question.title}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <QuestionListAI
                                        handleEditQuestion={handleEditQuestion}
                                        handleEditOption={handleEditOption}
                                        handleMakeQuestionEditable={handleMakeQuestionEditable}
                                        handleChangeQuestionVisibility={
                                          handleChangeQuestionVisibility
                                        }
                                        handleRemoveQuestion={handleRemoveQuestion}
                                        handleRemoveOption={handleRemoveOption}
                                        isQuestionEditable={isQuestionEditable}
                                        isQuestionVisible={isQuestionVisible}
                                        itemNumber={index + 1}
                                        provided={provided}
                                        question={question}
                                        questionType={question.question_type_spanish}
                                        title={question.title}
                                        handleAddOption={handleAddOption}
                                      />
                                    )}
                                  </Draggable>
                                ))}
                              </StyledQuestionsList>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </>
                    </StyledQuestionsContent>
                  </StyledCardWrapperPrompt>
                </StyledWrapperSurvey>
              ) : (
                <SpinnerLoading
                  isOpen={loader}
                  nested={true}
                  text={"¡Deja que la IA de Membeer haga su magia!"}
                />
              )}
            </StyledSurveyBackground>
            <StyledSubmitButtonContainer className="py-6 justify-center flex">
              <DefaultButton
                color="primary"
                disabled={toolStateAI?.questions?.length === 0}
                rounded
                type="submit"
              >
                Usar esta encuesta
              </DefaultButton>
            </StyledSubmitButtonContainer>
          </form>
        </div>
        <DefaultModal
          isOpen={showConfirmationModal}
          handledButton={handleCloseConfirmationModal}
          textButton={"Continuar"}
          children={
            <div>
              La encuesta <strong>{toolStateAI.name}</strong> <br /> ha sido agregada con éxito.
            </div>
          }
        />
      </StyledWrapper>
    </StyledContainer>
  );
};

export default AISurvey;
