import React, { useEffect, useRef } from 'react';

const CopilotInform = ({ htmlContent }) => {
  const contentContainerRef = useRef(null);

  useEffect(() => {
    if (contentContainerRef.current) {
      const htmlParser = new DOMParser();
      const parsedDoc = htmlParser.parseFromString(htmlContent, 'text/html');
      contentContainerRef.current.innerHTML = parsedDoc.body.innerHTML;
      

      const scriptElements = contentContainerRef.current.querySelectorAll('script');
      scriptElements.forEach((scriptElement) => {
        const scriptTag = document.createElement('script');
        if (scriptElement.src) {
          scriptTag.src = scriptElement.src;
          scriptTag.async = true;
        } else {
          scriptTag.textContent = scriptElement.textContent;
        }
        document.body.appendChild(scriptTag);
      });
    }
  }, [htmlContent]);

  return <div ref={contentContainerRef}></div>;
};

export default CopilotInform;
